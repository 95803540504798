import axios from 'axios'
import axiosConfig from './modules/axiosConfig'

import PageHome from './pages/Home.js'
import PageLogin from "./pages/Login";
import PageContato from "./pages/Contato";
import PageApoiadores from "./pages/Apoiadores";
import PageBlog from "./pages/Blog";
import PageSingleBlog from "./pages/Single_Blog";
import PageRelatorio from "./pages/Relatorios";

import UseMouseOrKeyboard from "./modules/UseMouseOrKeyboard";
import DesignSystem from "./DesignSystem/DesignSystem";
import { register } from 'swiper/element/bundle';
import logs from "./utils/logs";
import ripple from "./modules/ripple";
import formErros from "./modules/formErros";
import animaAoScroll from "./animation/animaAoScroll";
import animateSetup from "./animation/animateSetup";
// import SvgUse from "./utils/SvgUse";

import Menu from './modules/menu';
import Nav from './modules/Nav';
import EditorJS from './modules/Editor';
import contatoForm from './modules/Contact';


// Init
GLOBAL.DesignSystem = DesignSystem({})
register() // Swiper Web Component

// Config
axiosConfig({ csrf: GLOBAL.context?.csrf }, axios)
UseMouseOrKeyboard()
ripple()
formErros()
animateSetup()
animaAoScroll()

// Pages
PageHome()
PageLogin()?.init()
PageContato()
PageApoiadores()
PageBlog()
PageRelatorio()
PageSingleBlog()


Menu()
Nav()
EditorJS()
contatoForm()



// utils logs
logs()


