
import Swiper from "swiper";

const Swiper_Criancas = () => {


    const SwiperCriancas_1 = new Swiper(".SwiperCriancas_1", {
        slidesPerView: 1.3,
        spaceBetween: 16,
        loop: true,
        speed: 1200,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
        direction: "vertical",
    });

    const SwiperCriancas_2 = new Swiper(".SwiperCriancas_2", {
        slidesPerView: 1.5,
        spaceBetween: 16,
        loop: true,
        speed: 1200,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
            reverseDirection: true,
        },
        direction: "vertical",
    });
}

export default Swiper_Criancas;