import { find } from "../utils/dom";
import Swiper from "swiper";

const Page_Relatorio = () => {
    const Relatorio = find("[js-page='relatorio']");
    if (!Relatorio) return;

    const SwiperRelatorio = new Swiper(".Swiper-Relatorio", {
        slidesPerView: 1.2,
        spaceBetween: 16,
        centeredSlides: true,
        breakpoints: {
            600: {
                slidesPerView: 2.2,
            },
            767: {
                slidesPerView: 3,
                centeredSlides: false,
                spaceBetween: 32,
            }
        }

    });

    const SwiperDesenvolvimentoSustentavel = new Swiper(".Swiper-Desenvolvimento-Sustentavel", {
        slidesPerView: 1.3,
        spaceBetween: 16,
        speed: 1200,
        autoplay: {
            delay: 2500,
        },
        breakpoints: {
            600: {
                slidesPerView: 2.4,
            },
            767: {
                slidesPerView: 3.2,
            },
            1024: {
                slidesPerView: 3.5,
            },
            1200: {
                slidesPerView: 4.4,
            },
            1420: {
                slidesPerView: 5
            }
        }
    })

}

export default Page_Relatorio;