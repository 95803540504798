import { findAll, find } from "../utils/dom";
import axios from "axios";

const VideoYoutube = () => {
    const URLs = findAll('[js-url-youtube]');
    if (!URLs) return;


    async function Video_Info(videoURL, apiKey) {
        const videoId = videoURL.split('v=')[1];

        try {
            const response = await axios.get(`https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=snippet&key=${apiKey}`);

            const videoInfo = response.data.items[0].snippet;
            const title = videoInfo.title;
            const thumbnailUrl = videoInfo.thumbnails.maxres.url;
            const publishedAt = videoInfo.publishedAt;

            return { title, thumbnailUrl, publishedAt };
        } catch (error) {
            console.error('Erro ao acessar a API do YouTube:', error.message);
            return null;
        }
    }

    function mes_ano(mes) {

        const MesObject = [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Out',
            'Nov',
            'Dez',
        ]

        return (MesObject[parseInt(mes)]);


    }


    function construct_card(dados, link_video) {

        //20 de Fev, 2023
        const DataeHora = dados.publishedAt.split('T');
        const Data = new Date(DataeHora[0]);

        console.log(DataeHora[0])

        const Mes = mes_ano(Data.getMonth());
        const Ano = Data.getFullYear();
        const Dia = Data.getDate() + 1;


        return `
            <a href=${link_video} target="_blank" class="BG-Card-1 Card-hover-1 Card-Youtube">
                            
                <div class="relative Content-Card">
                    <h4 class="Card-Youtube-Data text-headline-4 text-white">
                        ${Dia} de ${Mes}, ${Ano}
                    </h4>
                    <h2 class="Card-Youtube-Titulo Limit-1 text-title-3 text-white">
                        ${dados.title}
                    </h2>
                </div>

                <figure class="absolute h-full w-full top-0 left-0">
                    <img loading="lazy" class="h-full w-full object-center object-cover img-zoom" src="${dados.thumbnailUrl}" alt="" />
                </figure>

                <div class="Icone-Card">
                    <svg class="text-white">
                        <use xlink:href="#icon-youtube-player"></use>
                    </svg>
                </div>
            </a>
        `
    }


    URLs.forEach(async (url) => {
        const link_url = url.getAttribute('js-url-youtube');
        const Tag_API_Key = find('[js-api-key-youtube]');
        const API_Key = Tag_API_Key.getAttribute('js-api-key-youtube');

        const dados = await Video_Info(link_url, API_Key);
        url.insertAdjacentHTML('beforeend', construct_card(dados, link_url));
    })


}

export default VideoYoutube;