import { find, findAll, width } from "../utils/dom";
import axios from "axios";

const Mapa = () => {

    function construct_card(Dados) {
        return (`
            <div class="Card-Instituicao">
                <div class="Card-Intituicao-Content">
                    <h4 class="text-headline-1 text-primary-brown-pure">
                        ${Dados.nome}
                    </h4>
                    <p class="text-paragraph-4 text-neutral-70">
                        ${Dados.endereco}
                    </p>
                    <a target='_blank' href="${Dados.link}" secundary class="Button">
                        Visitar site
                    </a>
                </div>
                
                <figure>
                    <img src="${Dados.brand}" alt="" />
                </figure>

            </div>
        `)
    }

    function construct_card_none() {
        return (`
                <div class='card-nenhuma-instituicao'>
                    <p>Nenhuma instituição encontrada.</p>
                </div>

            `)
    }

    function full_nome_estado(sigla) {
        if (sigla == 'RJ') {
            return "Rio de Janeiro"
        }
        else if (sigla == 'RN') {
            return "Rio Grande do Norte"
        }
        else if (sigla == 'PB') {
            return "Paraíba"
        }
        else if (sigla == 'BA') {
            return "Bahia"
        }
        else if (sigla == 'MG') {
            return "Minas Gerais"
        }
        else if (sigla == 'SP') {
            return "São Paulo"
        }
        else if (sigla == 'ES') {
            return "Espírito Santo"
        }
        else if (sigla == 'PE') {
            return "Pernambuco"
        }
        else if (sigla == 'AL') {
            return "Alagoas"
        }
        else if (sigla == 'GO') {
            return "Goiás"
        }
        else if (sigla == 'PR') {
            return "Paraná"
        }
        else if (sigla == 'RS') {
            return "Rio Grande do Sul"
        }
        else if (sigla == 'DF') {
            return "Distrito Federal"
        }
        else {
            return ""
        }
    }

    async function pesquisar_instituicao(sigla) {
        const protocolo = window.location.protocol;
        const host = window.location.host;
        const URL = `${protocolo}//${host}`;


        try {
            const response = await axios.get(`${URL}/api/instituicoes/${sigla}`);
            const Todos_Dados = response.data;
            const ContainerCard = find('[js-card-instituicao]');
            const Text_Total_Instituicoes = find('[js-total-instituicao]');
            const Full_Name = find('[js-full-name]');

            if (!ContainerCard) return;

            ContainerCard.innerHTML = '';
            let Total_Intituicoes = 0;

            for (let dado of Todos_Dados) {

                const Dados = {
                    "nome": dado.nome,
                    "sigla": dado.sigla_estado,
                    "link": dado.link_site,
                    "brand": dado.brand,
                    "endereco": dado.endereco,
                }

                ContainerCard.insertAdjacentHTML('beforeend', construct_card(Dados));
                Total_Intituicoes++;
            }

            Text_Total_Instituicoes.innerText = Total_Intituicoes;
            Full_Name.innerText = full_nome_estado(sigla)
            if (Total_Intituicoes == 0) {
                ContainerCard.insertAdjacentHTML('beforeend', construct_card_none());
            }

        } catch (error) {
            console.error('Erro ao acessar a API de instituicoes', error.message);
            return null;
        }
    }

    const MapaBrasil = document.querySelector('#mapa-brasil-geral');
    if (!MapaBrasil) return;

    const PinMapa = document.querySelector('#container-pin-mapa');
    const TextoEstadoPinMapa = PinMapa.querySelector('[js-text-pin-mapa]');

    const Estados = MapaBrasil.querySelectorAll('[js-estado]');

    if (Estados) {
        Estados.forEach((estado) => {
            estado.addEventListener("click", (e) => {
                const rect = MapaBrasil.getBoundingClientRect();

                const x = e.clientX - rect.left;
                const y = e.clientY - rect.top;

                //console.log(`translateY(${y - 90}px) translateX(${x - 40}px)`);

                PinMapa.classList.add('Reset-Pin');
                PinMapa.style.transform = `translateY(${y - 90}px) translateX(${x - 40}px)`;

                TextoEstadoPinMapa.innerText = e.target.getAttribute('js-estado');
                pesquisar_instituicao(e.target.getAttribute('js-estado'));
            })

        })
    }

    /** Posição Inicial do Pino */
    document.addEventListener("DOMContentLoaded", (e) => {
        pesquisar_instituicao('RJ');

    })
}

export default Mapa;