import { find } from "../utils/dom";

const Nav = () => {
    const Nav_Principal = find('[js-nav-principal]');
    if (!Nav_Principal) return;
    const tablet = window.matchMedia('(min-width: 1024px)');

    document.addEventListener("scroll", (event) => {
        const EixoY = window.scrollY;

        if (EixoY > 80 && tablet.matches) {
            Nav_Principal.classList.add("Nav-Fixed");
        }
        else {
            Nav_Principal.classList.remove("Nav-Fixed");
        }

        if (EixoY > 1200) {
            Nav_Principal.classList.add("reduz");
        }
        else {
            Nav_Principal.classList.remove("reduz");
        }
    })
}
export default Nav;