import { find } from "utils/dom"
import Swiper_Criancas from "../Swiper/SwiperCrianca"
import Swiper from "swiper"
import { findAll } from "../utils/dom"

export default function () {
    const Home = find("[js-page='apoiadores']")
    if (!Home) return

    /** Swipers */
    Swiper_Criancas();

    const SwiperParceiros = new Swiper(".Swiper-Parceiros", {
        slidesPerView: 1,
        grid: {
            rows: 5,
            fill: "row",
        },
        breakpoints: {
            767: {
                slidesPerView: 2,
                grid: {
                    rows: 5,
                    fill: "row",
                },
            }
        },
        spaceBetween: 0,
        pagination: {
            el: "[js-controle-swiper='Swiper-Parceiros'] .swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: "[js-controle-swiper='Swiper-Parceiros'] .swiper-button-next",
            prevEl: "[js-controle-swiper='Swiper-Parceiros'] .swiper-button-prev",
        },
    });

    const SwiperNavParceirosEmbaixadores = new Swiper(".Swiper-Nav-Parceiros-Embaixadores", {
        slidesPerView: "auto",
        spaceBetween: 8,

    })

    const SwiperCardApoiadores = new Swiper(".Swiper-Card-Apoiadores", {
        slidesPerView: 1.1,
        spaceBetween: 8,
        autoplay: {
            delay: 2500,
        },
        speed: 1000,

    })


    const Buttons = findAll('[js-button]');
    if (Buttons) {
        Buttons.forEach((button) => {
            button.addEventListener("click", (e) => {

                /** Botão */
                const Botao_Ativo = find('[js-button].active');
                Botao_Ativo.classList.remove('active');

                e.target.classList.add('active');

                /** Página */
                const Pagina_Para_Ativar = e.target.getAttribute("js-button");
                const Pagina_Ativa = find('[js-tipo-page].is-page-active');
                Pagina_Ativa.classList.remove('is-page-active');

                const Nova_Pagina_Ativa = find(`[js-tipo-page=${Pagina_Para_Ativar}]`);
                Nova_Pagina_Ativa.classList.add('is-page-active');
            })
        })
    }
    const busca = window.location.search
    let ObjetoBusca = busca.split('?')
    ObjetoBusca.forEach((item) => {
        if (item.includes('tipo-pagina')) {
            let Result = item.split('=');

            const Botao_Ativo = find('[js-button].active');
            Botao_Ativo.classList.remove('active');

            const Botao_Ativo_Novo = find(`[js-button=${Result[1]}]`);
            Botao_Ativo_Novo.classList.add('active');

            const Pagina_Ativa = find('[js-tipo-page].is-page-active');
            Pagina_Ativa.classList.remove('is-page-active');

            const Nova_Pagina_Ativa = find(`[js-tipo-page=${Result[1]}]`);
            Nova_Pagina_Ativa.classList.add('is-page-active');

        }
    })

}