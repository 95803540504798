import { find } from "../utils/dom";
import Swiper from "swiper";

const Page_Blog = () => {
    const Blog = find("[js-page='blog']");
    if (!Blog) return;

    const ListaBlog = new Swiper(".Swiper-Lista-Blog", {
        slidesPerView: 'auto',
        spaceBetween: 8,
        breakpoints: {
            767: {
                slidesPerView: 3.5,
            }
        }
    });

    const busca = window.location.search
    let ObjetoBusca = busca.split('?')
    ObjetoBusca = ObjetoBusca[1]?.split('&')
    ObjetoBusca?.forEach((item) => {
        if (item.includes('categoria')) {
            let Result = item.split('=');

            const Tag_Ativo = find('[js-categoria].active');
            Tag_Ativo.classList.remove('active');

            const Tag_Ativo_Novo = find(`[js-categoria=${Result[1]}]`);
            Tag_Ativo_Novo.classList.add('active');

            const BT_Next = find('[js-blog-next]');
            let Value_BT_Next = BT_Next.getAttribute('href');
            Value_BT_Next = `${Value_BT_Next}&${Result[0]}=${Result[1]}`
            BT_Next.setAttribute('href', Value_BT_Next);

            const BT_Prev = find('[js-blog-prev]');
            let Value_BT_Prev = BT_Prev.getAttribute('href');
            Value_BT_Prev = `${Value_BT_Prev}&${Result[0]}=${Result[1]}`
            BT_Prev.setAttribute('href', Value_BT_Prev);


        }
    })

}

export default Page_Blog;