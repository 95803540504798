import { find } from "../utils/dom";
import Swiper from "swiper";

const Swiper_Contact = () => {
    const Contact = new Swiper('.Swiper-Contact', {
        slidesPerView: 1,
        spaceBetween: 0,
        centeredSlides: true,
        effect: "fade",
        speed: 1200,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false
        },
    })
}

export default Swiper_Contact;