import Swiper from "swiper"
import { find } from "utils/dom"
import Mapa from "../modules/mapa"
import VideoYoutube from "../modules/Youtube"
import Swiper_Contact from "../Swiper/SwiperContact"
import { CountUp } from "../vendor/countUp";
import { findAll } from "../utils/dom"

export default function () {
	const Home = find("[js-page='home']")
	if (!Home) return




	const SwiperHeaderHome = new Swiper(".Swiper-Header-Home", {
		slidesPerView: 1,
		spaceBetween: 0,
		centeredSlides: true,
		effect: "fade",
		speed: 1400,
		autoplay: {
			delay: 5000,
			disableOnInteraction: false
		},
		pagination: {
			el: ".Swiper-Header-Home .swiper-pagination",
			clickable: true
		},
		navigation: {
			nextEl: ".Swiper-Header-Home .swiper-button-next",
			prevEl: ".Swiper-Header-Home .swiper-button-prev"
		},
		/*on: {
			autoplayTimeLeft(s, time, progress) {

				console.log(progress)
				progressCircle.style.setProperty("--progress", 1 - progress);
				progressContent.textContent = `${Math.ceil(time / 1000)}s`;
			}
		}*/

	})


	const SwiperCriancas_1 = new Swiper(".SwiperCriancas_1", {
		slidesPerView: 1.3,
		spaceBetween: 12,
		loop: true,
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
		},
		centeredSlides: true,
		direction: "vertical",
		breakpoints: {
			1024: {
				slidesPerView: 2.5,
				spaceBetween: 16,
			}
		}
	});

	const SwiperCriancas_2 = new Swiper(".SwiperCriancas_2", {
		slidesPerView: 1.3,
		spaceBetween: 12,
		loop: true,
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
			reverseDirection: true,
		},
		direction: "vertical",
		breakpoints: {
			1024: {
				slidesPerView: 2.5,
				spaceBetween: 16,
			}
		}
	});

	const SwiperBlog = new Swiper(".Swiper-Blog", {
		slidesPerView: 1.2,
		spaceBetween: 16,
		breakpoints: {
			600: {
				slidesPerView: 2.1,
				spaceBetween: 16,
			},
			1200: {
				slidesPerView: 3,
				spaceBetween: 32,
			}
		}
	})

	const SwiperYoutube = new Swiper(".Swiper-Youtube", {
		slidesPerView: 1.5,
		centeredSlides: true,
		spaceBetween: 16,
		loop: true,
		freeMode: {
			enabled: true,
			sticky: true,
		},
		autoplay: {
			delay: 3500,
		},
		navigation: {
			nextEl: "[js-controle-swiper='Swiper-Youtube'] .swiper-button-next",
			prevEl: "[js-controle-swiper='Swiper-Youtube'] .swiper-button-prev",
		},
		pagination: {
			el: "[js-controle-swiper='Swiper-Youtube'] .swiper-pagination",
			clickable: true,
		},
	})


	function criar_animation_big_number(id, Prefix, Suffix, numero) {
		new CountUp(id, numero, { enableScrollSpy: true, scrollSpyOnce: true, useGrouping: true, prefix: Prefix, suffix: Suffix, duration: 1, separator: "."});
	}

	function getPrefix(text) {
		let Prefix = ''
		if (!parseInt(text[0]) && text[0] != '0') {
			for (let i = 0; i < text.length; i++) {
				if (!parseInt(text[i]) && text[i] != '0') {
					Prefix += text[i]
				}
				else {
					break;
				}
			}
		}
		return Prefix;
	}
	function getSuffix(text) {
		let Suffix = ''
		if (!parseInt(text[text.length - 1]) && text[text.length - 1] != '0') {
			for (let i = text.length - 1; i >= 0; i--) {
				if (!parseInt(text[i]) && text[i] != '0') {
					Suffix += text[i]
				}
				else {
					break;
				}
			}
		}
		return Suffix;
	}
	function getNumber(text) {
		let Numero = '';
		for (let i = 0; i < text.length; i++) {
			if (parseInt(text[i]) || text[i] == '0') {
				Numero += text[i];
			}
		}

		return parseInt(Numero);
	}

	const BigNumbers = findAll('[js-big-number]');
	BigNumbers?.forEach((number) => {
		const text = number.innerText
		let Prefix = getPrefix(text)
		let Suffix = getSuffix(text)
		let Numero = getNumber(text)
		Suffix = Suffix.split("").reverse().join("");
		criar_animation_big_number(number.id, Prefix, Suffix, Numero);

	})

	Mapa();
	VideoYoutube();
	Swiper_Contact();

}