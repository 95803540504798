import { find } from "utils/dom"
import Swiper_Contact from "../Swiper/SwiperContact"

export default function () {
    const Contato = find("[js-page='contato']")
    if (!Contato) return


    Swiper_Contact();

}