import { find } from "../utils/dom";

const Menu = () => {
    const Button_Menu = find('[js-menu-button]');
    const Content_Menu = find('[js-menu-content]');

    if (Button_Menu) {
        Button_Menu.addEventListener("click", (e) => {
            if (e.target.classList.contains('active')) {
                e.target.classList.remove('active');
                Content_Menu.classList.remove('is_open');
            }
            else {
                e.target.classList.add('active');
                Content_Menu.classList.add('is_open');
            }
        })
    }

}

export default Menu;