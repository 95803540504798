import { find } from "../utils/dom";

const Single_Blog = () => {
    const SingleBlog = find("[js-page='single_blog']");
    if (!SingleBlog) return;


    const URL = window.location.href

    const ButtonLocation = find('[js-compartilhe]');
    ButtonLocation?.addEventListener("click", (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(URL);

        const mensagem = e.target.querySelector('.BT-Copiado');
        mensagem.classList.add('active');

        setTimeout(() => {
            mensagem.classList.remove('active');
        }, 1500)
    })

    const ButtonWhatssap = find('[js-compartilhe-whatssap]');
    let Value_Button_Whatssap = ButtonWhatssap.getAttribute('href');
    ButtonWhatssap.setAttribute('href', `${Value_Button_Whatssap}${URL}`)

    const ButtonLinkerdin = find('[js-compartilhe-linkerdin]');
    let Value_Button_Linkerdin = ButtonLinkerdin.getAttribute('href');
    ButtonLinkerdin.setAttribute('href', `${Value_Button_Linkerdin}${URL}`)

}
//js-compartilhe-whatssap
export default Single_Blog;